import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import HomeView from '@/views/HomeView.vue'
import HomeView from '@/views/pages/LandingPage.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [

    ]
  },
  {
    path: '/product-1',
    name: 'product-1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/pages/product-detail/ProductDesc1.vue')
  },
  {
    path: '/product-2',
    name: 'product-2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/pages/product-detail/ProductDesc2.vue')
  },
  {
    path: '/product-3',
    name: 'product-3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/pages/product-detail/ProductDesc3.vue')
  },
  {
    path: '/product-4',
    name: 'product-4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/pages/product-detail/ProductDesc4.vue')
  },
  {
    path: '/product-5',
    name: 'product-5',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/pages/product-detail/ProductDesc5.vue')
  },
  {
    path: '/product-6',
    name: 'product-6',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/pages/product-detail/ProductDesc6.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
