
import { defineComponent } from 'vue'
// import LandingPage from './views/pages/LandingPage.vue'
import MainTemplate from './views/template/main-template.vue'

export default defineComponent({
  components: {
    // LandingPage
    MainTemplate
  },
})
