
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'main-template',
    data() {
        return {
            iconName: "menu",
            hide: "hide-nav"
        }
    },
    methods: {
        openSideNav() {
            console.log(this.hide);
            if (this.hide == "show-nav") {
                this.hide = "hide-nav"
                this.iconName = "menu"
            } else {
                this.hide = "show-nav"
                this.iconName = "x"
            }
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        }
    }
})
