
import { defineComponent } from 'vue'
// import MainTemplate from '@/views/template/main-template.vue'
import FeaturePage from '@/views/pages/FeaturePage.vue'
import ProductPage from '@/views/pages/ProductPage.vue'
import FooterPage from '@/views/pages/FooterPage.vue'

export default defineComponent({
    name: 'landing-page',
    components: {
        // MainTemplate,
        FeaturePage,
        ProductPage,
        FooterPage
    },
    data() {
        return {

        }
    }
})
