
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'footer-page',
    data() {
        return {

        }
    }
})
